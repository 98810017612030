import React, { useRef, useState, KeyboardEvent, useEffect, RefObject, ChangeEvent } from "react";

interface CodeInputProps {
  length?: number;
  onChange?: (value: string) => void;
  disabled?: boolean;
  setTouched?: (status: boolean) => void;
  error?: string | string[];
  touched?: boolean;
  hideInput?: boolean;
  canFocus?: boolean;
}

interface OTPInputProps {
  length: number;
  mask: boolean;
  onChange: (otpValue: string) => void;
}

const CodeInput: React.FC<CodeInputProps> = (props) => {
  const { length, onChange, setTouched, error, touched, hideInput, disabled, canFocus = false } = props;
  const [code, setCode] = useState<string[]>(Array(length).fill(""));
  const inputRefs: RefObject<HTMLInputElement>[] = Array.from({ length }, () => React.createRef());
  const codeInputWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    canFocus && inputRefs[0].current?.focus();
  }, []);

  const handleInputChange = (index: number, value: string) => {
    if (/^\d*$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value !== "" && index < length - 1 && inputRefs[index + 1].current) {
        inputRefs[index + 1].current.focus();
      }

      onChange(newCode.join(""));
    }
  };

  const handleInputKeyUp = (index: number, event: KeyboardEvent<HTMLInputElement>) => {
    let key;

    if (event.key) {
      key = event.key;
    } else {
      var xCode = event.which || event.keyCode;
      key = String.fromCharCode(xCode);
    }

    if (key === "Backspace" && index > 0 && code[index] === "" && inputRefs[index - 1].current) {
      event.preventDefault(); // Prevents the default backspace behavior
      inputRefs[index - 1].current.focus();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("text");
    if (/^\d*$/.test(pastedData) && pastedData.length <= length) {
      const newCode = pastedData.split("").concat(Array(length - pastedData.length).fill(""));
      setCode(newCode);
      onChange(newCode.join(""));
    }
  };

  return (
    <>
      <div className="flex w-full justify-between" ref={codeInputWrapper}>
        {code.map((digit, index) => (
          <>
            <input
              key={index}
              className="h-12.5 w-11.5 sm:h-[56px] sm:w-12.5 no-outline  border border-grey-border border-opacity-70 code-input flex items-center text-center text-lg sm:text-xl font-extrabold text-grey-700 rounded-md focus:border-primary-500 transition-all duration-100 focus:placeholder-opacity-40 placeholder-placeholder disabled:cursor-not-allowed disabled:opacity-50 font-display text-black-secondary bg-grey-fields-100 bg-opacity-60 placeholder-shown:bg-white"
              type="text"
              // onKeyUp={(e) => handleInput(e, index)}
              disabled={disabled}
              onPaste={handlePaste}
              placeholder=" "
              onBlur={() => (setTouched ? setTouched(true) : null)}
              maxLength={1}
              value={hideInput && digit !== "" ? "*" : digit}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, e.target.value)}
              onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => handleInputKeyUp(index, e)}
              ref={inputRefs[index]}
              inputMode="numeric" // Ensure numeric keyboard on mobile
            />
            {index === Math.ceil(code.length / 2 - 1) && <div className="flex items-center text-grey-muted">-</div>}
          </>
        ))}
      </div>
      {error && touched && (
        <div className="text-accent-red-500 text-xs font-medium mt-1 flex items-center">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="mr-1">
            <path
              d="M7.018 3.55288L2.15392 11.6731C2.05363 11.8467 2.00057 12.0437 2 12.2442C1.99944 12.4447 2.0514 12.6419 2.15071 12.8162C2.25003 12.9904 2.39323 13.1356 2.56607 13.2373C2.73892 13.339 2.93538 13.3937 3.13592 13.3959H12.8641C13.0646 13.3937 13.2611 13.339 13.4339 13.2373C13.6068 13.1356 13.75 12.9904 13.8493 12.8162C13.9486 12.6419 14.0006 12.4447 14 12.2442C13.9994 12.0437 13.9464 11.8467 13.8461 11.6731L8.982 3.55288C8.87963 3.3841 8.73548 3.24456 8.56347 3.14772C8.39146 3.05088 8.1974 3 8 3C7.8026 3 7.60854 3.05088 7.43653 3.14772C7.26452 3.24456 7.12037 3.3841 7.018 3.55288V3.55288Z"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M8 6.50452V8.8016" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 11.0992H8.00718" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="inline-block pt-1">{error}</span>
        </div>
      )}
    </>
  );
};

const CodeInputOld: React.FC<CodeInputProps> = (props) => {
  const { length = 6, onChange, disabled, setTouched, error, touched, hideInput = false } = props;
  const [code, setCode] = useState<string[]>(Array(length).fill(""));
  const codeInputWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    onChange && onChange(code.join(""));
  }, [code]);

  function handleInput(e: KeyboardEvent<HTMLInputElement>, index: number) {
    let key;

    if (e.key) {
      key = e.key;
    } else {
      var xCode = e.which || e.keyCode;
      key = String.fromCharCode(xCode);
    }

    if (key === "Backspace") {
      const inputs = Array.from(codeInputWrapper.current.querySelectorAll("input"));
      const copiedCode = [...code];
      copiedCode[index] = "";

      setCode(copiedCode);
      if (index > 0) {
        inputs[index - 1].focus();
      }
      e.preventDefault();
    } else if (Number(key) || key === "0") {
      const inputs = Array.from(codeInputWrapper.current.querySelectorAll("input"));
      const copiedCode = [...code];
      copiedCode[index] = key;

      setCode(copiedCode);

      if (index < length - 1) {
        inputs[index + 1].focus();
      } else {
        inputs[index].blur();
      }
    } else {
      e.preventDefault();
    }
  }

  function handlePaste(e: React.ClipboardEvent) {
    e.preventDefault();
    let target = e.target as HTMLInputElement;

    let pastedValue = e.clipboardData?.getData("text").split("");
    const results = pastedValue?.splice(0, length).map((val) => (isNaN(Number(val)) ? "" : val));

    if (results) {
      setCode(results);
      target.blur();
    }
  }

  return (
    <>
      <div className="flex w-full justify-between" ref={codeInputWrapper}>
        {code.map((value, index) => (
          <>
            <input
              value={value}
              key={index}
              className="h-12.5 w-11.5 sm:h-[56px] sm:w-12.5 no-outline  border border-grey-border border-opacity-70 code-input flex items-center text-center text-lg sm:text-xl font-extrabold text-grey-700 rounded-md focus:border-primary-500 transition-all duration-100 focus:placeholder-opacity-40 placeholder-placeholder disabled:cursor-not-allowed disabled:opacity-50 font-display text-black-secondary bg-grey-fields-100 bg-opacity-60 placeholder-shown:bg-white"
              type={hideInput ? "password" : "number"}
              maxLength={1}
              // placeholder="-"
              onChange={() => {}}
              onKeyUp={(e) => handleInput(e, index)}
              disabled={disabled}
              onPaste={handlePaste}
              placeholder=" "
              onBlur={() => (setTouched ? setTouched(true) : null)}
            />
            {index === Math.ceil(code.length / 2 - 1) && <div className="flex items-center text-grey-muted">-</div>}
          </>
        ))}
      </div>
      {error && touched && (
        <div className="text-accent-red-500 text-xs font-medium mt-1 flex items-center">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="mr-1">
            <path
              d="M7.018 3.55288L2.15392 11.6731C2.05363 11.8467 2.00057 12.0437 2 12.2442C1.99944 12.4447 2.0514 12.6419 2.15071 12.8162C2.25003 12.9904 2.39323 13.1356 2.56607 13.2373C2.73892 13.339 2.93538 13.3937 3.13592 13.3959H12.8641C13.0646 13.3937 13.2611 13.339 13.4339 13.2373C13.6068 13.1356 13.75 12.9904 13.8493 12.8162C13.9486 12.6419 14.0006 12.4447 14 12.2442C13.9994 12.0437 13.9464 11.8467 13.8461 11.6731L8.982 3.55288C8.87963 3.3841 8.73548 3.24456 8.56347 3.14772C8.39146 3.05088 8.1974 3 8 3C7.8026 3 7.60854 3.05088 7.43653 3.14772C7.26452 3.24456 7.12037 3.3841 7.018 3.55288V3.55288Z"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M8 6.50452V8.8016" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 11.0992H8.00718" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="inline-block pt-1">{error}</span>
        </div>
      )}
    </>
  );
};

export default CodeInput;
