import React, { useEffect, useState } from "react";
import { CURRENCIES, StrippedItem } from "../../../assets/interfaces";
import { toCurrency } from "../../../assets/js/utils/functions";
import { useModals } from "../../hooks/useModals";
import AddItemModal from "../../payments/invoices/create/add-item-modal";
import LazyImage from "../../lazy-image";
import Portal from "../../portal";
import { AppBtn } from "../buttons";
import Modal, { ModalBody, ModalFooter } from "../modal";
import SearchBar from "../search-bar-new";
import Checkbox from "./checkbox";

interface Props {
  show: boolean;
  toggle: (show: boolean) => void;
  items: StrippedItem[];
  value: string[];
  saveItems: (items: StrippedItem[]) => void;
  addItem: (customer: StrippedItem) => void;
  includeWeight?: boolean;
  currency: CURRENCIES;
}

const ProductsSelectorModal: React.FC<Props> = (props) => {
  const { modals, toggleModal } = useModals(["add_item"]);
  const { show, toggle, items, value, saveItems, addItem, includeWeight, currency } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState(value);

  const filteredItems = (() => {
    if (searchQuery === "") {
      return items;
    }
    searchQuery.trim();
    const splitQueries = searchQuery.split(" ");

    return items.filter((item) => {
      let match = false;
      splitQueries.map((query) => {
        if (item.name.toLocaleLowerCase().includes(query)) match = true;
      });

      return match;
    });
  })();

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useEffect(() => {
    if (!show) setSearchQuery("");
  }, [show]);

  const toggleItem = (item: StrippedItem) => {
    const selectedIndex = selected.indexOf(item.id);
    const selectedCopy = [...selected];

    if (selectedIndex > -1) {
      selectedCopy.splice(selectedIndex, 1);
    } else {
      selectedCopy.push(item.id);
    }

    setSelected(selectedCopy);
  };

  const isItemSelected = (id: string) => selected.includes(id);

  const handleSave = () => {
    const itemsToSave: StrippedItem[] = selected.map((i) => items.find((item) => item.id === i));
    saveItems(itemsToSave);
    toggle(false);
  };

  return (
    <Portal>
      <Modal show={show} toggle={toggle} title="Select Products" size="md">
        <ModalBody className="relative !pt-0 p-5 sm:p-7.5" noPadding>
          <div className="sticky top-0 z-50 bg-white pt-4 pb-1">
            <SearchBar {...{ searchQuery, setSearchQuery }} />
            <div className="flex items-center justify-between mt-3 pb-3 border-b border-grey-border border-opacity-50">
              <span className="font-medium text-sm text-dark">Need a different item?</span>
              <button
                className="px-2.5 py-1.5 bg-grey-fields-100 text-primary-500 font-medium rounded-[30px] no-outline text-1xs"
                type="button"
                onClick={() => toggleModal("add_item")}
              >
                + Create Item
              </button>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
            {filteredItems.map((item, index) => (
              <div
                key={index}
                className="w-fit flex items-start py-4 pr-4 border-b-[1px] border-gray-100 last:border-0 cursor-pointer overflow-hidden"
                onClick={() => toggleItem(item)}
                role="button"
              >
                <div className="flex items-center flex-1 overflow-hidden">
                  <figure className="flex-shrink-0 h-[34px] w-[34px] rounded-lg overflow-hidden mr-3 relative">
                    {item?.image && (
                      <LazyImage
                        src={item.image}
                        className="h-full w-full object-cover rounded-md relative z-10"
                        alt={item.name}
                      />
                    )}

                    {!item?.image && (
                      <div className={`w-full h-full flex items-center justify-center bg-accent-yellow-pastel`}>
                        <h4 className="text-lg">📦</h4>
                      </div>
                    )}
                  </figure>
                  <div className="w-[75%]">
                    <span className="text-sm sm:text-1sm mt-0.75 overflow-hidden whitespace-nowrap overflow-ellipsis text-black-secondary block -mb-0.5">
                      {item.name}
                    </span>
                    <span className="text-xs font-semibold text-black">{toCurrency(item.price, currency, true)}</span>
                  </div>
                </div>
                <Checkbox
                  onChange={() => toggleItem(item)}
                  checked={isItemSelected(item.id)}
                  id={item.id}
                  name={item.name}
                  neutral
                  className="ml-auto mt-1"
                  round
                  small
                ></Checkbox>
              </div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="w-full">
            <AppBtn onClick={handleSave} isBlock size="lg">
              Select Items
            </AppBtn>
          </div>
        </ModalFooter>
      </Modal>
      <AddItemModal
        show={modals.add_item.show}
        toggle={() => toggleModal("add_item")}
        addItem={(item) => {
          addItem(item);
          setTimeout(() => {
            toggleItem(item);
          }, 300);
        }}
        includeWeight={includeWeight}
      />
    </Portal>
  );
};

export function getProductColors(name: string = "") {
  const char = name.charAt(0).toLowerCase();

  switch (true) {
    case char >= "a" && char <= "f":
      return "bg-accent-yellow-500 text-accent-yellow-500";
    case char >= "g" && char <= "m":
      return "bg-accent-orange-500 text-accent-orange-500";
    case char >= "n" && char <= "s":
      return "bg-accent-green-500 text-accent-green-500";
    case char >= "t" && char <= "z":
      return "bg-accent-red-500 text-accent-red-500";
    default:
      return "bg-accent-orange-500 text-accent-orange-500";
  }
}

export default ProductsSelectorModal;
