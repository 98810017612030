import { useState } from "react";
import { AppBtn } from "../buttons";
import Modal, { ModalBody, ModalFooter } from "../modal";
import { Calendar } from "react-date-range";
import { formatDateString, getDateRangeText } from "../../../assets/js/utils/utils";

interface Props {
  show: boolean;
  toggle: (state: boolean) => void;
  onSave: (date: Date) => void;
  value: Date;
  minDate?: Date;
  maxDate?: Date;
}

const DatePickerModal: React.FC<Props> = ({ show, toggle, onSave, value, maxDate, minDate }) => {
  const [error, setError] = useState({ show: false, message: "" });
  const [date, setDate] = useState(value);

  const handleSave = () => {
    if (date == null) {
      setError({ show: true, message: "Select a valid date" });
      return;
    }
    onSave(date);
  };

  const handleSelect = (date) => {
    setDate(date);
  };

  return (
    <Modal {...{ show, toggle }} title="Select Date" size="sm">
      <ModalBody className="">
        <div className="-mt-5">
          <Calendar
            color={["#332089"]}
            minDate={minDate ?? undefined}
            maxDate={maxDate ?? undefined}
            showMonthAndYearPickers={true}
            date={date}
            onChange={handleSelect}
            shownDate={maxDate ? maxDate : null}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="w-full">
          {!error.show && (
            <div className="flex items-center">
              {/* prettier-ignore */}
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none"  >
                <path d="M6.66666 5.29102C6.325 5.29102 6.04166 5.00768 6.04166 4.66602V2.16602C6.04166 1.82435 6.325 1.54102 6.66666 1.54102C7.00833 1.54102 7.29166 1.82435 7.29166 2.16602V4.66602C7.29166 5.00768 7.00833 5.29102 6.66666 5.29102Z" fill="#656565"/>
                <path d="M13.3333 5.29102C12.9917 5.29102 12.7083 5.00768 12.7083 4.66602V2.16602C12.7083 1.82435 12.9917 1.54102 13.3333 1.54102C13.675 1.54102 13.9583 1.82435 13.9583 2.16602V4.66602C13.9583 5.00768 13.675 5.29102 13.3333 5.29102Z" fill="#656565"/>
                <path d="M7.08333 12.5839C6.975 12.5839 6.86667 12.5589 6.76667 12.5173C6.65833 12.4756 6.575 12.4172 6.49167 12.3422C6.34167 12.1839 6.25 11.9756 6.25 11.7506C6.25 11.6423 6.275 11.5339 6.31667 11.4339C6.35833 11.3339 6.41667 11.2423 6.49167 11.1589C6.575 11.0839 6.65833 11.0256 6.76667 10.9839C7.06667 10.8589 7.44167 10.9256 7.675 11.1589C7.825 11.3173 7.91667 11.5339 7.91667 11.7506C7.91667 11.8006 7.90833 11.8589 7.9 11.9173C7.89167 11.9673 7.875 12.0173 7.85 12.0673C7.83333 12.1173 7.80834 12.1673 7.775 12.2173C7.75 12.2589 7.70833 12.3006 7.675 12.3422C7.51667 12.4922 7.3 12.5839 7.08333 12.5839Z" fill="#656565"/>
                <path d="M10 12.5837C9.89166 12.5837 9.78333 12.5587 9.68333 12.5171C9.575 12.4754 9.49166 12.417 9.40833 12.342C9.25833 12.1837 9.16666 11.9754 9.16666 11.7504C9.16666 11.642 9.19167 11.5337 9.23333 11.4337C9.275 11.3337 9.33333 11.2421 9.40833 11.1587C9.49166 11.0837 9.575 11.0254 9.68333 10.9837C9.98333 10.8504 10.3583 10.9254 10.5917 11.1587C10.7417 11.3171 10.8333 11.5337 10.8333 11.7504C10.8333 11.8004 10.825 11.8587 10.8167 11.9171C10.8083 11.9671 10.7917 12.0171 10.7667 12.0671C10.75 12.1171 10.725 12.167 10.6917 12.217C10.6667 12.2587 10.625 12.3004 10.5917 12.342C10.4333 12.492 10.2167 12.5837 10 12.5837Z" fill="#656565"/>
                <path d="M12.9167 12.5837C12.8083 12.5837 12.7 12.5587 12.6 12.5171C12.4917 12.4754 12.4083 12.417 12.325 12.342C12.2917 12.3004 12.2583 12.2587 12.225 12.217C12.1917 12.167 12.1667 12.1171 12.15 12.0671C12.125 12.0171 12.1083 11.9671 12.1 11.9171C12.0917 11.8587 12.0833 11.8004 12.0833 11.7504C12.0833 11.5337 12.175 11.3171 12.325 11.1587C12.4083 11.0837 12.4917 11.0254 12.6 10.9837C12.9083 10.8504 13.275 10.9254 13.5083 11.1587C13.6583 11.3171 13.75 11.5337 13.75 11.7504C13.75 11.8004 13.7417 11.8587 13.7333 11.9171C13.725 11.9671 13.7083 12.0171 13.6833 12.0671C13.6667 12.1171 13.6417 12.167 13.6083 12.217C13.5833 12.2587 13.5417 12.3004 13.5083 12.342C13.35 12.492 13.1333 12.5837 12.9167 12.5837Z" fill="#656565"/>
                <path d="M7.08333 15.5C6.975 15.5 6.86667 15.475 6.76667 15.4333C6.66667 15.3917 6.575 15.3333 6.49167 15.2583C6.34167 15.1 6.25 14.8833 6.25 14.6667C6.25 14.5583 6.275 14.45 6.31667 14.35C6.35833 14.2417 6.41667 14.15 6.49167 14.075C6.8 13.7667 7.36667 13.7667 7.675 14.075C7.825 14.2333 7.91667 14.45 7.91667 14.6667C7.91667 14.8833 7.825 15.1 7.675 15.2583C7.51667 15.4083 7.3 15.5 7.08333 15.5Z" fill="#656565"/>
                <path d="M10 15.5C9.78333 15.5 9.56666 15.4083 9.40833 15.2583C9.25833 15.1 9.16666 14.8833 9.16666 14.6667C9.16666 14.5583 9.19167 14.45 9.23333 14.35C9.275 14.2417 9.33333 14.15 9.40833 14.075C9.71666 13.7667 10.2833 13.7667 10.5917 14.075C10.6667 14.15 10.725 14.2417 10.7667 14.35C10.8083 14.45 10.8333 14.5583 10.8333 14.6667C10.8333 14.8833 10.7417 15.1 10.5917 15.2583C10.4333 15.4083 10.2167 15.5 10 15.5Z" fill="#656565"/>
                <path d="M12.9167 15.4994C12.7 15.4994 12.4833 15.4078 12.325 15.2578C12.25 15.1828 12.1917 15.0911 12.15 14.9828C12.1083 14.8828 12.0833 14.7744 12.0833 14.6661C12.0833 14.5578 12.1083 14.4494 12.15 14.3494C12.1917 14.2411 12.25 14.1494 12.325 14.0744C12.5167 13.8828 12.8083 13.7911 13.075 13.8494C13.1333 13.8578 13.1833 13.8744 13.2333 13.8994C13.2833 13.9161 13.3333 13.9411 13.3833 13.9745C13.425 13.9995 13.4667 14.0411 13.5083 14.0744C13.6583 14.2328 13.75 14.4494 13.75 14.6661C13.75 14.8828 13.6583 15.0994 13.5083 15.2578C13.35 15.4078 13.1333 15.4994 12.9167 15.4994Z" fill="#656565"/>
                <path d="M17.0833 8.69922H2.91666C2.575 8.69922 2.29166 8.41589 2.29166 8.07422C2.29166 7.73255 2.575 7.44922 2.91666 7.44922H17.0833C17.425 7.44922 17.7083 7.73255 17.7083 8.07422C17.7083 8.41589 17.425 8.69922 17.0833 8.69922Z" fill="#656565"/>
                <path d="M13.3333 19.4577H6.66667C3.625 19.4577 1.875 17.7077 1.875 14.666V7.58268C1.875 4.54102 3.625 2.79102 6.66667 2.79102H13.3333C16.375 2.79102 18.125 4.54102 18.125 7.58268V14.666C18.125 17.7077 16.375 19.4577 13.3333 19.4577ZM6.66667 4.04102C4.28333 4.04102 3.125 5.19935 3.125 7.58268V14.666C3.125 17.0493 4.28333 18.2077 6.66667 18.2077H13.3333C15.7167 18.2077 16.875 17.0493 16.875 14.666V7.58268C16.875 5.19935 15.7167 4.04102 13.3333 4.04102H6.66667Z" fill="#656565"/>
              </svg>
              <div className="ml-2 flex items-center text-dark">
                {<span className="text-sm">{date ? formatDateString(date) : "Date"}</span>}
              </div>
            </div>
          )}
          {error.show && (
            <div className="text-red-500 text-xs font-medium flex items-center">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="mr-1">
                <path
                  d="M7.018 3.55288L2.15392 11.6731C2.05363 11.8467 2.00057 12.0437 2 12.2442C1.99944 12.4447 2.0514 12.6419 2.15071 12.8162C2.25003 12.9904 2.39323 13.1356 2.56607 13.2373C2.73892 13.339 2.93538 13.3937 3.13592 13.3959H12.8641C13.0646 13.3937 13.2611 13.339 13.4339 13.2373C13.6068 13.1356 13.75 12.9904 13.8493 12.8162C13.9486 12.6419 14.0006 12.4447 14 12.2442C13.9994 12.0437 13.9464 11.8467 13.8461 11.6731L8.982 3.55288C8.87963 3.3841 8.73548 3.24456 8.56347 3.14772C8.39146 3.05088 8.1974 3 8 3C7.8026 3 7.60854 3.05088 7.43653 3.14772C7.26452 3.24456 7.12037 3.3841 7.018 3.55288V3.55288Z"
                  stroke="#FA5454"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path d="M8 6.50452V8.8016" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 11.0992H8.00718" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className="inline-block pt-1">{error.message}</span>
            </div>
          )}
          <AppBtn className="mt-3" isBlock onClick={handleSave} size="lg">
            Select Date
          </AppBtn>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default DatePickerModal;
