import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import cx from "classnames";
import usePreventFirstRun from "../../hooks/usePreventFirstRun";

interface Props extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  name?: string;
  label: string;
  options: { text: string; value: any; alt?: string }[];
  emptyLabel?: string;
  action?: {
    onClick: () => void;
    label: string;
  };
  onChange?: (e: any) => void;
  hasSearch?: Boolean;
  searchLabel?: string;
  error?: string;
  errors?: any;
  addon?: ReactNode;
  value?: any[];
  onBlur?: (e: any) => void;
}

const MultiSelectDropdown: React.FC<Props> = (props) => {
  const {
    label,
    options,
    value,
    className,
    action,
    emptyLabel,
    hasSearch,
    searchLabel,
    name,
    onChange,
    error,
    addon,
    onBlur,
  } = props;
  const [selected, setSelected] = useState<any[]>(value ? value : []);
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState(options);

  const itemsSelected = selected.length > 0;

  useEffect(() => {
    if (hasSearch) {
      const results = [...options].filter(
        ({ text, value }) =>
          text.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
          value.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
      );

      setSearchResults(results);
      return;
    }

    setSearchResults(options);
  }, [searchText, options]);

  useEffect(() => {
    if (value && JSON.stringify(value) !== JSON.stringify(selected)) {
      setSelected(value);
    }
  }, [value]);

  usePreventFirstRun(() => {
    if (!open && onBlur) {
      onBlur({ target: { name } });
    }
  }, [open]);

  useClickOutside(dropdownRef, () => {
    setOpen(false);
  });

  const selectItem = (value: any) => {
    const valueIndex = selected.indexOf(value);
    const selectedCopy = [...selected];

    if (valueIndex > -1) {
      selectedCopy.splice(valueIndex, 1);
    } else {
      selectedCopy.push(value);
    }

    setSelected(selectedCopy);

    if (onChange) {
      onChange({ target: { value: selectedCopy, name, id: name } });
    }
  };

  const isSelected = (value: any) => selected.indexOf(value) > -1;
  const getTextFromValue = (selected: any) => [...options].find(({ value }) => value === selected)?.text;

  return (
    <div className={`mt-3.5 first:mt-0 w-full ${className}`}>
      <div className={`h-11.5 w-full relative flex items-center font-action`} ref={dropdownRef}>
        <button
          className={`input-field h-full w-full !outline-none border rounded-lg text-1sm text-dark px-4 sm:px-5 flex items-center focus:border-primary-500 ${
            error
              ? "border-danger-500"
              : itemsSelected
              ? "border-grey-border border-opacity-60"
              : "border border-grey-divider"
          } ${open || itemsSelected ? "bg-white" : "bg-grey-fields-100 bg-opacity-30 "}`}
          type="button"
          onClick={() => setOpen(!open)}
        >
          {addon && <div className="pr-2 -ml-2">{addon}</div>}
          <label
            className={cx(
              "text-sm text-placeholder absolute cursor-text pointer-events-none transform py-1 px-1.25 -translate-x-1.25 flex-shrink-0",
              {
                "bg-white top-0 -translate-y-1/2 text-xs leading-none": open || itemsSelected,
                "ml-5": addon !== undefined,
              }
            )}
          >
            {label}
          </label>
          <div className="flex items-center space-x-2 overflow-x-auto mr-auto" onClick={(e) => e.stopPropagation()}>
            {selected.map((s, index) => (
              <button
                key={index}
                className="flex items-center space-x-0.75 text-dark text-xs sm:text-1xs px-2 py-1.25 rounded-5 bg-grey-ash whitespace-nowrap"
                type="button"
                onClick={() => selectItem(s)}
              >
                <span className="inline-block">{getTextFromValue(s)}</span>
                {/* prettier-ignore */}
                <svg width="13.5" viewBox="0 0 15 15" fill="none">
                  <path d="M11.25 3.75L3.75 11.25" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M3.75 3.75L11.25 11.25" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
            ))}
            {itemsSelected && <div className="px-3 inline-block"></div>}
          </div>
          <div
            className={cx("absolute right-4 sm:right-5 h-[80%] flex items-center", { "bg-white": itemsSelected })}
            style={{
              boxShadow: itemsSelected ? "-10px 0 10px rgba(255,255,255, 1)" : "",
            }}
          >
            {/* prettier-ignore */}
            <svg width="18" viewBox="0 0 20 20" fill="none" className={`transition-transform ml-auto text-dark ${open ? "transform rotate-180" : ""} `}>
              <path d="M15 7.5L10 12.5L5 7.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
        </button>
        <div
          className={`absolute top-full bg-white shadow-card rounded-10 border border-grey-header z-[200] w-full transform transition-all duration-100 ease-in-out overflow-hidden ${
            open ? "opacity-1 translate-y-2" : "pointer-events-none opacity-0 translate-y-4"
          }`}
        >
          {hasSearch && (
            <div className="flex items-center sticky top-0" onClick={(e) => e.stopPropagation()}>
              <figure className="absolute left-2.5 h-5 w-5 p-0.75">
                {/* prettier-ignore */}
                <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none">
                  <path d="M5.44444 9.88889C7.89904 9.88889 9.88889 7.89904 9.88889 5.44444C9.88889 2.98985 7.89904 1 5.44444 1C2.98985 1 1 2.98985 1 5.44444C1 7.89904 2.98985 9.88889 5.44444 9.88889Z" stroke="#8C8FA3" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M10.9999 11.0002L8.58328 8.5835" stroke="#8C8FA3" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </figure>
              <input
                type="text"
                placeholder={`${searchLabel ? searchLabel : "Search options"}`}
                onInput={(e: any) => setSearchText(e.target.value)}
                className="outline-none border-b text-1xs placeholder-placeholder border-grey-card-outline px-2.5 py-3.75 w-full leading-none pl-8 transition-all focus:border-primary-500 focus:border-opacity-60 text-dark focus:outline-none"
              />
            </div>
          )}

          {searchResults.length > 0 && (
            <ul className="flex flex-col divide-y divide-grey-divider max-h-[220px] overflow-y-auto">
              {searchResults.map(({ value, text, alt }, index) => (
                <li
                  className={cx("py-3 px-5 flex items-center text-sm cursor-pointer hover:bg-grey-light", {
                    "bg-grey-light text-black-secondary": isSelected(value),
                    "text-dark": !isSelected(value),
                  })}
                  onClick={() => selectItem(value)}
                  key={index}
                >
                  <div
                    className={cx(
                      "h-4.5 w-4.5 rounded-full mr-2.5 border-[1.5px] flex items-center justify-center transition-all ease-out duration-75 flex-shrink-0",
                      {
                        "border-accent-green-500 bg-accent-green-500": isSelected(value),
                        "border-grey-outline": !isSelected(value),
                      }
                    )}
                  >
                    {/* prettier-ignore */}
                    <svg height="8" viewBox="0 0 11 8" fill="none" className={cx("transition-all ease-out duration-75", { "opacity-0": !isSelected(value), "opacity-100": isSelected(value) })}>
                      <path d="M1 3.83L3.83 6.66L9.5 1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </div>
                  <span className="inline-flex">{alt ?? text}</span>
                </li>
              ))}
            </ul>
          )}

          {searchResults.length < 1 && (
            <div className="py-4 px-5 text-placeholder text-center text-xs cursor-pointer">
              {emptyLabel || "No options to show"}
            </div>
          )}

          {action && (
            <button
              type="button"
              onClick={action.onClick}
              className="w-full font-body border-t border-grey-divider no-outline py-3 px-5 flex items-center justify-center text-primary-500 font-semibold text-sm cursor-pointer hover:bg-grey-light"
            >
              {action.label}
            </button>
          )}
        </div>
      </div>
      {error && (
        <div className="text-accent-red-500 text-xs font-medium mt-1 flex items-center">
          {/* prettier-ignore */}
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="mr-1">
            <path d="M7.018 3.55288L2.15392 11.6731C2.05363 11.8467 2.00057 12.0437 2 12.2442C1.99944 12.4447 2.0514 12.6419 2.15071 12.8162C2.25003 12.9904 2.39323 13.1356 2.56607 13.2373C2.73892 13.339 2.93538 13.3937 3.13592 13.3959H12.8641C13.0646 13.3937 13.2611 13.339 13.4339 13.2373C13.6068 13.1356 13.75 12.9904 13.8493 12.8162C13.9486 12.6419 14.0006 12.4447 14 12.2442C13.9994 12.0437 13.9464 11.8467 13.8461 11.6731L8.982 3.55288C8.87963 3.3841 8.73548 3.24456 8.56347 3.14772C8.39146 3.05088 8.1974 3 8 3C7.8026 3 7.60854 3.05088 7.43653 3.14772C7.26452 3.24456 7.12037 3.3841 7.018 3.55288V3.55288Z" stroke="#FA5454" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 6.50452V8.8016" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 11.0992H8.00718" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="inline-block">{error}</span>
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
