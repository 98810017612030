import classNames from "classnames";
import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from "react";

export interface ModalProps {
  show: boolean;
  toggle: (state: boolean) => void;
  size?: "sm" | "md" | "midi" | "lg";
  title?: string;
  closeable?: boolean;
  noBg?: boolean;
  bgClose?: boolean;
  className?: string;
  showHeader?: boolean;
  children?: ReactNode;
}

const Modal: FunctionComponent<ModalProps> = (props) => {
  const {
    show,
    toggle,
    size = "md",
    title,
    children,
    closeable = true,
    noBg = false,
    bgClose = true,
    className = "",
    showHeader = true,
  } = props;
  const [showModal, setShowModal] = useState(show);

  useEffect(() => {
    if (!show && show !== showModal) {
      setTimeout(() => {
        setShowModal(show);
      }, 301);
    } else {
      setShowModal(show);
    }
  }, [show]);

  const sizeClasses = {
    sm: "top-[5%] w-11/12 max-w-[420px] rounded-10 sm:rounded-15",
    midi: "bottom-0 sm:top-[80px] w-full sm:w-11/12 sm:max-w-[480px] max-h-[80vh] rounded-t-10 sm:rounded-15",
    md: "bottom-0 sm:top-[80px] w-full sm:w-11/12 sm:max-w-[540px] max-h-[80vh] rounded-t-10 sm:rounded-15",
    lg: "bottom-0 sm:top-[80px] w-full sm:w-11/12 sm:max-w-[650px] max-h-[80vh] rounded-t-10 sm:rounded-15",
  };

  const wrapperClasses = classNames(
    "bg-black bg-opacity-70 fixed top-0 left-0 right-0 bottom-0 z-[999] flex justify-center cursor-pointer items-start",
    {
      "modal-wrapper-anim": show,
      "modal-wrapper-anim-rev": !show,
      "bg-opacity-70 blur-5": !noBg,
      "bg-opacity-0": noBg,
    },
    className
  );

  const mainClasses = classNames(
    "bg-white w-full absolute sm:relative flex flex-col cursor-default sm:overflow-hidden",
    {
      "modal-body-anim": show,
      "modal-body-anim-rev": !show,
    },
    sizeClasses[size]
  );

  return (
    <>
      {showModal ? (
        <div className={wrapperClasses} onClick={(e) => (bgClose && closeable ? toggle(false) : {})}>
          <div className={mainClasses} onClick={(e) => e.stopPropagation()}>
            {showHeader && (
              <div className="bg-grey-modal w-full flex items-center justify-between py-5 px-6.25 rounded-t-10 sm:rounded-t-15">
                <h4 className="text-black text-left text-[17px] font-semibold flex-1 overflow-hidden whitespace-nowrap overflow-ellipsis mr-5">
                  {title}
                </h4>
                {closeable && <CloseButton {...{ size, toggle }} />}
              </div>
            )}

            {!showHeader && closeable && <CloseButton {...{ size, toggle }} noHeader={!showHeader} />}
            {children}
          </div>
        </div>
      ) : null}
    </>
  );
};

interface CloseBtnProps {
  size: "sm" | "md" | "midi" | "lg";
  toggle: (state: boolean) => void;
  className?: string;
  noHeader?: boolean;
}

const CloseButton: React.FC<CloseBtnProps> = ({ size, toggle, className, noHeader }) => {
  return (
    <button
      type="button"
      onClick={() => toggle(false)}
      className={classNames(
        `no-outline h-6.25 w-6.25 rounded-full ml-auto transition-all text-placeholder hover:border-primary-500 hover:text-primary-500 flex items-center justify-center`,
        {
          "transform -translate-y-15 translate-x-4.5 sm:translate-y-0 sm:translate-x-0": size !== "sm" && !noHeader,
          "absolute right-0 -translate-y-10 -translate-x-3 sm:translate-y-3 sm:-translate-x-3 z-[100]": noHeader,
        },
        className
      )}
    >
      {/* prettier-ignore */}
      <svg width="100%" viewBox="0 0 25 25" fill="none">
        <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="white" stroke="currentColor"/>
        <path d="M16.25 8.75L8.75 16.25" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.75 8.75L16.25 16.25" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </button>
  );
};

const ModalBody: React.FC<{
  className?: string;
  noPadding?: boolean;
  children?: ReactNode;
  setRef?: (ref?: React.MutableRefObject<HTMLDivElement>) => void;
}> = ({ children, className, noPadding = false, setRef }) => {
  const bodyRef = useRef();
  useEffect(() => {
    if (bodyRef.current && setRef) setRef(bodyRef);
  }, []);
  return (
    <div className={`flex-auto overflow-y-auto ${noPadding ? "" : "p-5 sm:p-6.25"} ${className}`} ref={bodyRef}>
      {children}
    </div>
  );
};

const ModalFooter: React.FC<{ className?: string; children?: ReactNode }> = ({ children, className }) => {
  return (
    <div className={`flex items-center py-3 sm:py-3.75 px-5 sm:px-6.25 border-t border-grey-divider ${className}`}>
      {children}
    </div>
  );
};

export default Modal;

export { ModalBody, ModalFooter };
