import { useEffect, useRef, useState } from "react";

interface LazyImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  src: string;
  showLoader?: boolean;
  loaderClasses?: string;
}

const LazyImage: React.FC<LazyImageProps> = ({ src, showLoader = true, loaderClasses, ...props }) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    const imageObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(imageRef.current);
          }
        });
      },
      { threshold: 0.1 }
    );

    imageObserver.observe(imageRef.current);

    return () => imageObserver.disconnect();
  }, []);

  function getImage() {
    if (isVisible) {
      return src;
    }
    return "";
  }

  return (
    <>
      {!hasLoaded && showLoader && (
        <div className={`bg-grey-loader h-full w-full absolute top-0 animate-pulse ${loaderClasses}`}></div>
      )}
      <img
        src={getImage()}
        {...props}
        style={!hasLoaded ? { opacity: 0 } : {}}
        ref={imageRef}
        onLoad={() => setHasLoaded(true)}
      />
    </>
  );
};

export default LazyImage;
