import React, { useEffect, useRef, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import Portal from "../../portal";
import { AppBtn } from "../buttons";
import Modal, { ModalBody, ModalFooter } from "../modal";
import SearchBar from "../search-bar-new";
import { DropdownOptionInterface } from "./select-dropdown";
import usePreventFirstRun from "@/components/hooks/usePreventFirstRun";
import { serializeErrors } from "@/assets/js/utils/functions";
import classNames from "classnames";

interface Props
  extends Omit<React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>, "onBlur"> {
  name?: string;
  label: string;
  options: DropdownOptionInterface[];
  emptyLabel?: string;
  action?: {
    onClick: () => void;
    label: string;
  };
  onChange?: (e: any) => void;
  hasSearch?: Boolean;
  searchLabel?: string;
  error?: string;
  errors?: any;
  onBlur?: VoidFunction | any;
  isLoadingData?: boolean;
  setTouched?: (state: boolean) => void;
  value?: string[];
}

const MultiSelectPillsModal: React.FC<Props> = (props) => {
  const {
    label,
    options,
    value,
    className,
    action,
    emptyLabel,
    hasSearch,
    searchLabel,
    name,
    onChange,
    error,
    errors,
    disabled,
    onBlur,
    isLoadingData,
    setTouched,
  } = props;
  const [selected, setSelected] = useState<any[]>(value ?? []);
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState(options);

  const isSelected = (value: any) => selected.includes(value);

  const selectItem = (value: any) => {
    let selectedCopy = [...selected];
    const valueIndex = selectedCopy.indexOf(value);

    if (valueIndex < 0) {
      selectedCopy.push(value);
    } else {
      selectedCopy.splice(valueIndex, 1);
    }

    setSelected(selectedCopy);
    if (onChange) {
      onChange({ target: { value: selectedCopy, name, id: name } });
    }
  };

  useEffect(() => {
    if (hasSearch) {
      const results = [...options].filter(
        ({ text, value }) =>
          text.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
          String(value).toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
      );

      setSearchResults(results);
      return;
    }

    setSearchResults(options);
  }, [searchText, options]);

  useEffect(() => {
    if (value !== selected) {
      setSelected(value);
    }
  });

  usePreventFirstRun(() => {
    if (!open) {
      setSearchText("");

      setTouched && setTouched(true);
    }
  }, [open]);

  // useClickOutside(dropdownRef, () => {
  //   setOpen(false);
  // });

  return (
    <div onBlur={onBlur} className={`mt-4 first:mt-0 ${className}`}>
      <div
        className={`h-12.5 w-full relative flex items-center font-action ${disabled ? "opacity-70" : ""}`}
        ref={dropdownRef}
      >
        <button
          className={`input-field h-full w-full !outline-none border rounded-lg text-1sm text-black-secondary px-4 sm:px-5 flex items-center justify-between ${
            error ? "border-accent-red-500" : selected ? "border-grey-divider" : "border-grey-divider"
          } ${open || selected ? "bg-white" : "bg-grey-fields-100 bg-opacity-40"} ${
            disabled ? "" : "focus:border-primary-500"
          }`}
          type="button"
          disabled={disabled}
          onClick={() => (disabled ? null : setOpen(!open))}
        >
          <label
            className={`text-sm text-placeholder absolute cursor-text pointer-events-none transform py-1 px-1.25 -translate-x-1.25  ${
              open || selected.length > 0 ? "bg-white top-0 -translate-y-1/2 text-xs leading-none" : ""
            }`}
          >
            {label}
          </label>

          {selected.length > 0 && (
            <span className="text-black-secondary text-1sm">{selected.length} items selected</span>
          )}

          {/* prettier-ignore */}
          <svg width="18" viewBox="0 0 20 20" fill="none" className={`transition-transform ml-auto text-dark ${open ? "transform rotate-180" : ""} `}>
            <path d="M15 7.5L10 12.5L5 7.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </div>
      {error && (
        <div className="text-accent-red-500 text-xs font-medium mt-1 flex items-center">
          {/* prettier-ignore */}
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="mr-1">
            <path
              d="M7.018 3.55288L2.15392 11.6731C2.05363 11.8467 2.00057 12.0437 2 12.2442C1.99944 12.4447 2.0514 12.6419 2.15071 12.8162C2.25003 12.9904 2.39323 13.1356 2.56607 13.2373C2.73892 13.339 2.93538 13.3937 3.13592 13.3959H12.8641C13.0646 13.3937 13.2611 13.339 13.4339 13.2373C13.6068 13.1356 13.75 12.9904 13.8493 12.8162C13.9486 12.6419 14.0006 12.4447 14 12.2442C13.9994 12.0437 13.9464 11.8467 13.8461 11.6731L8.982 3.55288C8.87963 3.3841 8.73548 3.24456 8.56347 3.14772C8.39146 3.05088 8.1974 3 8 3C7.8026 3 7.60854 3.05088 7.43653 3.14772C7.26452 3.24456 7.12037 3.3841 7.018 3.55288V3.55288Z"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M8 6.50452V8.8016" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 11.0992H8.00718" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="inline-block pt-1">{error}</span>
        </div>
      )}

      <Portal>
        <Modal size="midi" title={label} show={open} toggle={setOpen}>
          <ModalBody className="!pt-0">
            {hasSearch && (
              <div className="sticky top-0 pt-6.25 pb-2.5 bg-white z-[999]">
                <SearchBar placeholder={searchLabel} {...{ searchQuery: searchText, setSearchQuery: setSearchText }} />
              </div>
            )}

            {searchResults.length > 0 && !isLoadingData && (
              <ul className="flex flex-row flex-wrap items-start mt-3">
                {searchResults.map((option, index) => (
                  <li
                    className={classNames(
                      "py-2.5 px-3.75 flex items-start text-sm cursor-pointer rounded-30 mb-3 mr-3 transition-all ease-out duration-150 border",
                      {
                        "border-primary-500 text-primary-500 bg-primary-pastel": isSelected(option.value),
                        "text-dark bg-grey-fields-100 border-transparent": !isSelected(option.value),
                      }
                    )}
                    onClick={() => selectItem(option.value)}
                    key={index}
                  >
                    {option.text}
                  </li>
                ))}
              </ul>
            )}

            {searchResults.length < 1 && !isLoadingData && (
              <div className="py-5 px-2.5 text-placeholder text-center text-xs cursor-pointer">
                {emptyLabel || "No options to show"}
              </div>
            )}

            {isLoadingData && (
              <div className="py-12 flex items-center justify-center">
                <div className="spinner spinner--md text-primary-500"></div>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <AppBtn size="lg" isBlock onClick={() => setOpen(false)}>
              Continue
            </AppBtn>
          </ModalFooter>
        </Modal>
      </Portal>
    </div>
  );
};

export default MultiSelectPillsModal;
