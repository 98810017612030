import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import cx from "classnames";
import { generateHoursInterval } from "../../../assets/js/utils/functions";
import usePreventFirstRun from "../../hooks/usePreventFirstRun";

interface Props extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  name?: string;
  label: string;
  onChange?: (e: any) => void;
  error?: string;
  errors?: any;
  value?: [string, string];
  onBlur?: (e: any) => void;
}

const TimeRangeSelect: React.FC<Props> = (props) => {
  const { label, value, className, name, onChange, error, onBlur } = props;
  const [selected, setSelected] = useState<any[]>(value ?? ["09:00AM", "O7:00PM"]);
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [selecting, setSelecting] = useState<"START" | "END">(null);
  const options = [...generateHoursInterval(0, 60 * 24, 30), "11:59PM"];

  const itemsSelected = selected.length > 0;

  useEffect(() => {
    if (value && JSON.stringify(value) !== JSON.stringify(selected)) {
      setSelected(value);
    }
  }, [value]);

  useClickOutside(dropdownRef, () => {
    setOpen(false);
  });

  usePreventFirstRun(() => {
    if (!open && onBlur) {
      onBlur({ target: { name } });
    }
  }, [open]);

  const selectItem = (value: any) => {
    const valueIndex = selecting === "START" ? 0 : 1;
    const selectedCopy = [...selected];

    selectedCopy[valueIndex] = value;
    setSelected(selectedCopy);

    if (onChange) {
      onChange({ target: { value: selectedCopy, name, id: name } });
    }
  };

  const isSelected = (value: string, part: "START" | "END") => selected[part === "START" ? 0 : 1] === value;
  const toggleSelector = (part: "START" | "END") => () => {
    setSelecting(part);
    if (part === selecting) {
      setOpen(!open);
    } else {
      setOpen(true);
    }
  };

  return (
    <div className={`mt-3.5 first:mt-0 ${className}`}>
      <div className={`h-11.5 w-full relative flex items-center font-action`} ref={dropdownRef}>
        <button
          className={`input-field h-full w-full !outline-none border rounded-lg text-1sm text-dark px-4 sm:px-5 flex items-center focus:border-primary-500 bg-white ${
            error
              ? "border-accent-red-500"
              : itemsSelected
              ? "border border-grey-divider "
              : "bg-grey-fields-100 bg-opacity-30"
          }`}
          type="button"
          onClick={() => setOpen(false)}
        >
          <div className="mr-1.5 -ml-2 text-placeholder">
            {/* prettier-ignore */}
            <svg width="22" viewBox="0 0 22 22" fill="none">
              <path d="M10.9997 20.8538C5.56384 20.8538 1.14551 16.4355 1.14551 10.9997C1.14551 5.56384 5.56384 1.14551 10.9997 1.14551C16.4355 1.14551 20.8538 5.56384 20.8538 10.9997C20.8538 16.4355 16.4355 20.8538 10.9997 20.8538ZM10.9997 2.52051C6.32467 2.52051 2.52051 6.32467 2.52051 10.9997C2.52051 15.6747 6.32467 19.4788 10.9997 19.4788C15.6747 19.4788 19.4788 15.6747 19.4788 10.9997C19.4788 6.32467 15.6747 2.52051 10.9997 2.52051Z" fill="currentColor"/>
              <path d="M14.4008 14.6021C14.2816 14.6021 14.1624 14.5746 14.0524 14.5013L11.2108 12.8055C10.5049 12.3838 9.98242 11.458 9.98242 10.6421V6.88379C9.98242 6.50796 10.2941 6.19629 10.6699 6.19629C11.0458 6.19629 11.3574 6.50796 11.3574 6.88379V10.6421C11.3574 10.9721 11.6324 11.458 11.9166 11.623L14.7583 13.3188C15.0883 13.5113 15.1891 13.933 14.9966 14.263C14.8591 14.483 14.6299 14.6021 14.4008 14.6021Z" fill="currentColor"/>
            </svg>
          </div>
          <label className="text-placeholder absolute cursor-text pointer-events-none transform py-1 px-1.25 -translate-x-1.25 bg-white top-0 -translate-y-1/2 text-xs leading-none ml-5">
            {label}
          </label>
          <div
            className="flex items-center space-x-2 overflow-x-auto mr-auto text-1xs"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="px-1.5 py-1 rounded-5 hover:bg-grey-fields-100 hover:text-primary-500 transition-all ease-out duration-75"
              onClick={toggleSelector("START")}
              type="button"
            >
              {selected[0]}
            </button>
            <span>-</span>
            <button
              className="px-1.5 py-1 rounded-5 hover:bg-grey-fields-100 hover:text-primary-500 transition-all ease-out duration-75"
              onClick={toggleSelector("END")}
              type="button"
            >
              {selected[1]}
            </button>
          </div>
        </button>
        <div
          className={`absolute top-full bg-white shadow-card rounded-10 border border-grey-header z-[200] w-full transform transition-all duration-100 ease-in-out overflow-hidden ${
            open ? "opacity-1 translate-y-2" : "pointer-events-none opacity-0 translate-y-4"
          }`}
        >
          {options.length > 0 && (
            <ul className="flex flex-col divide-y divide-grey-divider max-h-[220px] overflow-y-auto">
              {options.map((option, index) => (
                <li
                  className={cx("py-3 px-5 flex items-center text-sm cursor-pointer hover:bg-grey-light", {
                    "bg-grey-light text-black-secondary": isSelected(option, selecting),
                    "text-dark": !isSelected(option, selecting),
                  })}
                  onClick={() => selectItem(option)}
                  key={index}
                >
                  {/* prettier-ignore */}
                  <svg height="10" viewBox="0 0 11 8" fill="none" className={cx("text-accent-green-500 transform transition-all duration-100 ease-in-out", { "w-0": !isSelected(option, selecting), "w-auto mr-1.5" :isSelected(option, selecting)})}>
                    <path d="M1 3.83L3.83 6.66L9.5 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <span className="inline-flex mt-0.5">{option}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {error && (
        <div className="text-accent-red-500 text-xs font-medium mt-1 flex items-center">
          {/* prettier-ignore */}
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="mr-1">
            <path d="M7.018 3.55288L2.15392 11.6731C2.05363 11.8467 2.00057 12.0437 2 12.2442C1.99944 12.4447 2.0514 12.6419 2.15071 12.8162C2.25003 12.9904 2.39323 13.1356 2.56607 13.2373C2.73892 13.339 2.93538 13.3937 3.13592 13.3959H12.8641C13.0646 13.3937 13.2611 13.339 13.4339 13.2373C13.6068 13.1356 13.75 12.9904 13.8493 12.8162C13.9486 12.6419 14.0006 12.4447 14 12.2442C13.9994 12.0437 13.9464 11.8467 13.8461 11.6731L8.982 3.55288C8.87963 3.3841 8.73548 3.24456 8.56347 3.14772C8.39146 3.05088 8.1974 3 8 3C7.8026 3 7.60854 3.05088 7.43653 3.14772C7.26452 3.24456 7.12037 3.3841 7.018 3.55288V3.55288Z" stroke="#FA5454" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 6.50452V8.8016" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 11.0992H8.00718" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="inline-block">{error}</span>
        </div>
      )}
    </div>
  );
};

export default TimeRangeSelect;
