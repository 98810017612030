import { useFormik } from "formik";
import React, { useEffect } from "react";
import { AppBtn } from "../../../ui/buttons";
import Modal, { ModalBody, ModalFooter } from "../../../ui/modal";
import * as Yup from "yup";
import { InputField } from "../../../ui/form-elements";
import { getFieldvalues, getRandString } from "../../../../assets/js/utils/functions";
import { InputWithAddon } from "../../../ui/form-elements/input-field";
import { useRequest } from "../../../../api/utils";
import { CreateCustomItem } from "../../../../api/items";
import { CreateCustomItemParams } from "../../../../api/interfaces/items.interface";
import ErrorLabel from "../../../ui/error-label";
import SuccessLabel from "../../../ui/success-label";
import { StrippedItem } from "../../../../assets/interfaces";
import authContext from "@/contexts/auth-context";

interface Props {
  show: boolean;
  toggle: (status: boolean) => void;
  addItem: (item: StrippedItem) => void;
  includeWeight?: boolean;
}

const AddItemModal: React.FC<Props> = ({ show, toggle, addItem, includeWeight }) => {
  const { makeRequest, response, error, isLoading, clearResponse } =
    useRequest<CreateCustomItemParams>(CreateCustomItem);

  const { store } = authContext.useContainer();

  useEffect(() => {
    if (show) {
      form.setFieldValue("id", getRandString(12));
    }
  }, [show]);

  const form = useFormik<StrippedItem>({
    initialValues: {
      id: getRandString(12),
      price: 0,
      name: "",
      image: "",
      weight: null,
    },
    onSubmit: async (values) => {
      const [res, err] = await makeRequest({ name: values.name, price: values.price, weight: Number(values?.weight) });

      if (res) {
        const { weight, ...excludeWeight } = values;

        addItem(includeWeight ? values : excludeWeight);
        setTimeout(() => {
          toggle(false);
          form.resetForm();
        }, 1000);
      }
    },
    validationSchema: validationSchema(includeWeight),
  });

  useEffect(() => {
    if (!show) {
      form.resetForm();
      clearResponse();
    }
  }, [show]);

  return (
    <Modal {...{ show, toggle }} title="Add Item" size="midi">
      <div className="flex flex-col flex-auto overflow-hidden">
        <ModalBody>
          <ErrorLabel error={error?.message ?? null} />
          <SuccessLabel message={response ? "Item added successfully!" : null} />
          <InputField label="Item Name" {...getFieldvalues("name", form)} />
          <InputWithAddon
            placeholder="Item Price"
            {...getFieldvalues("price", form)}
            onChange={(e) => form.setFieldValue("price", Number(e.target.value))}
            inputMode="numeric"
          >
            <div className="bg-white text-dark h-full flex items-center text-xs rounded-l-10 px-3 font-medium">
              {store.currencies?.products}
            </div>
          </InputWithAddon>
          {includeWeight && <InputField label="Item Weight" inputMode="numeric" {...getFieldvalues("weight", form)} />}
        </ModalBody>
        <ModalFooter>
          <AppBtn isBlock onClick={() => form.handleSubmit()} disabled={isLoading}>
            {isLoading ? "Adding item..." : "Add Item"}
          </AppBtn>
        </ModalFooter>
      </div>
    </Modal>
  );
};

const validationSchema = (includeWeight) =>
  Yup.object().shape({
    name: Yup.string().required("Item name is required"),
    price: Yup.number().required("Item price is required").min(1, "Item price must be greater than 0"),
    weight: includeWeight
      ? Yup.number()
          .typeError("Item weight should be a number")
          .required("Item weight is required")
          .min(1, "Item weight should be greater than 0")
      : undefined,
  });

export default AddItemModal;
