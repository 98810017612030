import React, { useEffect, useRef, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import usePreventFirstRun from "../../hooks/usePreventFirstRun";
import { objectsAreEqual } from "@/assets/js/utils/utils";
import { is } from "cheerio/lib/api/traversing";

export interface DropdownOptionInterface {
  text: string;
  value: any;
  meta?: any;
}

interface Props
  extends Omit<React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>, "onBlur"> {
  name?: string;
  label: string;
  options: DropdownOptionInterface[];
  emptyLabel?: string;
  action?: {
    onClick: () => void;
    label: string;
  };
  onChange?: (e: any) => void;
  hasSearch?: Boolean;
  searchLabel?: string;
  error?: string;
  errors?: any;
  OptionRender?: React.FunctionComponent<{ option: DropdownOptionInterface; isOption?: boolean }>;
  onBlur?: VoidFunction | any;
  // specialBlur?: VoidFunction | any;
  selectionRemovable?: boolean;
  children?: React.ReactNode;
  showAfter?: boolean;
  isAddon?: boolean;
}

const SelectDropdown: React.FC<Props> = (props) => {
  const {
    label,
    options,
    value,
    className,
    action,
    emptyLabel,
    hasSearch,
    searchLabel,
    name,
    onChange,
    error,
    OptionRender,
    disabled,
    onBlur,
    selectionRemovable,
    isAddon = false,
  } = props;
  const [selected, setSelected] = useState<any>(value);
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState(options);

  const selectedOption = [...options].find(({ value }) => objectsAreEqual(selected, value));

  const selectItem = (value: any) => {
    setSelected(value);
    setOpen(false);

    if (onChange) {
      onChange({ target: { value, name, id: name } });
    }

    // setTimeout(() => {
    //   if (onBlur && typeof onBlur === "function") {
    //     onBlur({ target: { name } });
    //   }
    // }, 500);
  };

  usePreventFirstRun(() => {
    if (!open && onBlur) {
      onBlur({ target: { name } });
    }
  }, [open]);

  useEffect(() => {
    if (hasSearch) {
      const results = [...options].filter(
        ({ text, value }) =>
          text.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
          value.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
      );

      setSearchResults(results);
      return;
    }

    setSearchResults(options);
  }, [searchText, options]);

  useEffect(() => {
    if (value !== selected) {
      setSelected(value);
    }
  });

  useClickOutside(dropdownRef, () => {
    setOpen(false);
  });

  return (
    <div className={`${isAddon ? "h-full" : "mt-4 first:mt-0 w-full"} ${className}`}>
      <div
        className={`relative ${isAddon ? "h-full" : "h-12.5"} ${isAddon ? "flex items-center" : ""}`}
        ref={dropdownRef}
      >
        <button
          className={`w-full h-full !outline-none text-black-secondary flex items-center justify-between ${
            error && !isAddon ? "border-accent-red-500" : "border-grey-divider"
          } ${open || selected ? "bg-white" : "bg-grey-fields-100 bg-opacity-40"} ${
            disabled ? "" : "focus:border-primary-500"
          } ${isAddon ? "rounded-l-lg px-3 text-1xs pt-1" : "border rounded-lg px-4 sm:px-5 text-1sm"}`}
          type="button"
          disabled={disabled}
          onClick={() => (disabled ? null : setOpen(!open))}
        >
          {/* Conditionally render label */}
          {!isAddon && (
            <label
              className={`text-sm text-placeholder absolute cursor-text pointer-events-none transform py-1 px-1.25 -translate-x-1.25 ${
                open || selected ? "bg-white top-0 -translate-y-1/2 text-xs leading-none" : ""
              }`}
            >
              {label}
            </label>
          )}

          {/* Selected Option */}
          {OptionRender && selectedOption && <OptionRender option={selectedOption} isOption={false} />}
          {selectedOption && !OptionRender && (
            <span
              className={`text-black-secondary inline-block max-w-full overflow-ellipsis whitespace-nowrap overflow-hidden text-left mr-1.5 ${
                isAddon ? "text-1xs" : "text-1sm"
              }`}
            >
              {selectedOption.text || label}
            </span>
          )}

          {/* Arrow Icon */}
          <svg
            width="18"
            viewBox="0 0 20 20"
            fill="none"
            className={`transition-transform ml-auto text-dark ${open ? "transform rotate-180" : ""}`}
          >
            <path
              d="M15 7.5L10 12.5L5 7.5"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div
          className={`absolute top-full bg-white shadow-card rounded-10 border border-grey-header z-[200] w-full transform transition-all duration-100 ease-in-out overflow-hidden ${
            open ? "opacity-1 translate-y-2" : "pointer-events-none opacity-0 translate-y-4"
          }`}
        >
          {hasSearch && (
            <div className="flex items-center sticky top-0" onClick={(e) => e.stopPropagation()}>
              <figure className="absolute left-2.5 h-5 w-5 p-0.75">
                {/* prettier-ignore */}
                <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none">
                  <path d="M5.44444 9.88889C7.89904 9.88889 9.88889 7.89904 9.88889 5.44444C9.88889 2.98985 7.89904 1 5.44444 1C2.98985 1 1 2.98985 1 5.44444C1 7.89904 2.98985 9.88889 5.44444 9.88889Z" stroke="#8C8FA3" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M10.9999 11.0002L8.58328 8.5835" stroke="#8C8FA3" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </figure>
              <input
                type="text"
                placeholder={`${searchLabel ? searchLabel : "Search options"}`}
                onInput={(e: any) => setSearchText(e.target.value)}
                className="outline-none border-b text-base placeholder-placeholder border-grey-border border-opacity-50 px-2.5 py-4 w-full leading-none pl-8 transition-all focus:border-primary-500 focus:border-opacity-60 text-dark focus:outline-none"
              />
            </div>
          )}

          {searchResults.length > 0 && (
            <ul className="flex flex-col divide-y divide-grey-divider max-h-[220px] overflow-y-auto">
              {searchResults.map((option, index) => (
                <li
                  className={`py-3 px-5 flex items-center text-dark cursor-pointer hover:bg-grey-light text-left ${
                    isAddon ? "text-1xs" : "text-sm"
                  }`}
                  onClick={() => selectItem(option.value)}
                  key={index}
                >
                  {OptionRender ? (
                    <OptionRender option={option} isOption={true} />
                  ) : (
                    <span className="inline-block max-w-full overflow-ellipsis whitespace-nowrap overflow-hidden">
                      {option.text}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          )}

          {searchResults.length < 1 && (
            <div className="py-5 px-5 text-placeholder text-center text-xs cursor-pointer">
              {emptyLabel || "No options to show"}
            </div>
          )}

          {action && (
            <button
              type="button"
              onClick={action.onClick}
              className="w-full border-t border-grey-divider no-outline py-3 px-5 flex items-center justify-center text-primary-500 font-medium text-1xs cursor-pointer hover:bg-grey-light"
            >
              {action.label}
            </button>
          )}

          {selectionRemovable && selected && (
            <button
              type="button"
              onClick={() => selectItem("")}
              className="w-full border-t border-grey-divider no-outline py-3 px-5 flex items-center justify-center text-accent-red-500 font-medium text-xs cursor-pointer hover:bg-grey-light"
            >
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" className="mr-1">
                <path
                  d="M9 3L3 9"
                  stroke="currentColor"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 3L9 9"
                  stroke="currentColor"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Remove Selection
            </button>
          )}
        </div>
      </div>
      {error && (
        <div className="text-accent-red-500 text-xs font-medium mt-1 flex items-center">
          {/* prettier-ignore */}
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="mr-1">
            <path d="M7.018 3.55288L2.15392 11.6731C2.05363 11.8467 2.00057 12.0437 2 12.2442C1.99944 12.4447 2.0514 12.6419 2.15071 12.8162C2.25003 12.9904 2.39323 13.1356 2.56607 13.2373C2.73892 13.339 2.93538 13.3937 3.13592 13.3959H12.8641C13.0646 13.3937 13.2611 13.339 13.4339 13.2373C13.6068 13.1356 13.75 12.9904 13.8493 12.8162C13.9486 12.6419 14.0006 12.4447 14 12.2442C13.9994 12.0437 13.9464 11.8467 13.8461 11.6731L8.982 3.55288C8.87963 3.3841 8.73548 3.24456 8.56347 3.14772C8.39146 3.05088 8.1974 3 8 3C7.8026 3 7.60854 3.05088 7.43653 3.14772C7.26452 3.24456 7.12037 3.3841 7.018 3.55288V3.55288Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 6.50452V8.8016" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 11.0992H8.00718" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="inline-block pt-1">{typeof error === "object" ? Object.values(error)[0] : error}</span>{" "}
          {/* in case error is an object */}
        </div>
      )}
    </div>
  );
};

export default SelectDropdown;
