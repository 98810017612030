import React, { useEffect, useState } from "react";

const SuccessLabel: React.FC<{ message?: string; perm?: boolean }> = ({ message, perm = false }) => {
  const [display, setDisplay] = useState("");

  useEffect(() => {
    if (message) {
      setDisplay(message);

      if (!perm) {
        setTimeout(() => {
          setDisplay("");
        }, 5000);
      }
    } else {
      setDisplay("");
    }
  }, [message]);

  return (
    <>
      {display ? (
        <div className="w-full bg-accent-green-500 bg-opacity-10 text-accent-green-500 text-1xs font-medium py-2 px-2.5 text-center mb-6 rounded-5">
          <span className="inline-block">{display}</span>
        </div>
      ) : null}
    </>
  );
};

export default SuccessLabel;
