import React, { FunctionComponent, useState } from "react";
import InputField, { InputFieldProps } from "./input-field";

const PasswordField: FunctionComponent<InputFieldProps> = ({ ...props }) => {
  const [type, setType] = useState("password");

  const changeType = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  return (
    <InputField {...props} type={type}>
      <button
        className="no-outline text-black-placeholder uppercase font-medium text-xs absolute right-3.5 sm:right-4"
        onClick={() => changeType()}
        type="button"
      >
        {type === "password" ? (
          /* prettier-ignore */
          <svg className="w-5" viewBox="0 0 21 20" fill="none" >
            <path d="M10.499 13.6083C8.50729 13.6083 6.89062 11.9916 6.89062 9.99993C6.89062 8.00827 8.50729 6.3916 10.499 6.3916C12.4906 6.3916 14.1073 8.00827 14.1073 9.99993C14.1073 11.9916 12.4906 13.6083 10.499 13.6083ZM10.499 7.6416C9.19896 7.6416 8.14062 8.69993 8.14062 9.99993C8.14062 11.2999 9.19896 12.3583 10.499 12.3583C11.799 12.3583 12.8573 11.2999 12.8573 9.99993C12.8573 8.69993 11.799 7.6416 10.499 7.6416Z" fill="currentColor"/>
            <path d="M10.5004 17.5167C7.36706 17.5167 4.40872 15.6834 2.37539 12.5001C1.49206 11.1251 1.49206 8.8834 2.37539 7.50006C4.41706 4.31673 7.37539 2.4834 10.5004 2.4834C13.6254 2.4834 16.5837 4.31673 18.6171 7.50006C19.5004 8.87506 19.5004 11.1167 18.6171 12.5001C16.5837 15.6834 13.6254 17.5167 10.5004 17.5167ZM10.5004 3.7334C7.80872 3.7334 5.23372 5.35007 3.43372 8.17507C2.80872 9.15007 2.80872 10.8501 3.43372 11.8251C5.23372 14.6501 7.80872 16.2667 10.5004 16.2667C13.1921 16.2667 15.7671 14.6501 17.5671 11.8251C18.1921 10.8501 18.1921 9.15007 17.5671 8.17507C15.7671 5.35007 13.1921 3.7334 10.5004 3.7334Z" fill="currentColor"/>
          </svg>
        ) : (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M9.41317 9.41319C9.23007 9.60969 9.00927 9.76729 8.76394 9.8766C8.51861 9.98591 8.25377 10.0447 7.98523 10.0494C7.71669 10.0542 7.44995 10.0048 7.20091 9.90418C6.95188 9.80359 6.72565 9.65387 6.53573 9.46396C6.34582 9.27404 6.1961 9.04782 6.09551 8.79878C5.99492 8.54975 5.94552 8.283 5.95026 8.01446C5.955 7.74592 6.01378 7.48108 6.12309 7.23575C6.2324 6.99042 6.39001 6.76962 6.5865 6.58652M11.9598 11.9599C10.8202 12.8285 9.43258 13.3098 7.99984 13.3332C3.33317 13.3332 0.666504 7.99985 0.666504 7.99985C1.49576 6.45445 2.64593 5.10426 4.03984 4.03985L11.9598 11.9599ZM6.59984 2.82652C7.05872 2.71911 7.52855 2.66541 7.99984 2.66652C12.6665 2.66652 15.3332 7.99985 15.3332 7.99985C14.9285 8.75693 14.4459 9.46968 13.8932 10.1265L6.59984 2.82652Z"
              stroke="currentColor"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M0.666504 0.666504L15.3332 15.3332"
              stroke="currentColor"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </button>
    </InputField>
  );
};

export default PasswordField;
